import { default as indexBrHftL1JLBMeta } from "/var/www/nuxt-v3/prod/releases/30/pages/index.vue?macro=true";
import { default as simulation_45pinelgSOAtQGVCNMeta } from "/var/www/nuxt-v3/prod/releases/30/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptz9sB8mxPcPLMeta } from "/var/www/nuxt-v3/prod/releases/30/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: indexBrHftL1JLBMeta?.name ?? "index",
    path: indexBrHftL1JLBMeta?.path ?? "/",
    meta: indexBrHftL1JLBMeta || {},
    alias: indexBrHftL1JLBMeta?.alias || [],
    redirect: indexBrHftL1JLBMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/30/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pinelgSOAtQGVCNMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pinelgSOAtQGVCNMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pinelgSOAtQGVCNMeta || {},
    alias: simulation_45pinelgSOAtQGVCNMeta?.alias || [],
    redirect: simulation_45pinelgSOAtQGVCNMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/30/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptz9sB8mxPcPLMeta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptz9sB8mxPcPLMeta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptz9sB8mxPcPLMeta || {},
    alias: simulation_45ptz9sB8mxPcPLMeta?.alias || [],
    redirect: simulation_45ptz9sB8mxPcPLMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/30/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]